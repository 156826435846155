import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Divider.module.scss';

const Divider = ({ padded }) => (
  <hr
    className={classNames(
      styles.divider,
      `${padded ? styles['divider--padded'] : ''}`
    )}
  />
);

Divider.propTypes = {
  padded: PropTypes.bool,
};

Divider.defaultProps = {
  padded: false,
};

export default Divider;
